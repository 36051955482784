import { Grid, TextField } from '@emburse/embark';
import { DescriptionRow } from '.';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../../types/api/serialized_company_request';

interface ICompanyRequestUserInfoProps {
  companyRequest: ISerializedCompanyRequest;
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
}

export function CompanyRequestUserInfo(props: ICompanyRequestUserInfoProps): JSX.Element {
  const { companyRequest, onChange } = props;
  return (
    <>
      <DescriptionRow description="User Info" />
      <Grid container direction="row" spacing={5} wrap="wrap">
        <Grid item xs={6} md={3}>
          <TextField
            id="initial_user_first_name"
            type="text"
            value={companyRequest.initial_user_first_name}
            label="First Name"
            onChange={(event: any) => onChange(event.target.value, 'initial_user_first_name')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            id="initial_user_last_name"
            type="text"
            value={companyRequest.initial_user_last_name}
            label="Last Name"
            onChange={(event: any) => onChange(event.target.value, 'initial_user_last_name')}
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <TextField
            id="initial_user_email_address"
            type="text"
            value={companyRequest.initial_user_email_address}
            label="Email Address"
            onChange={(event: any) => onChange(event.target.value, 'initial_user_email_address')}
          />
        </Grid>
      </Grid>
    </>
  );
}
