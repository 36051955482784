import { Typography, Card, Grid, TextField, Button, ButtonContainer } from '@emburse/embark';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { CompanyRequestAddressInfo, CompanyRequestUserInfo, DescriptionRow } from './subComponents';

interface ICompanyRequestCreationComponentProps {
  companyRequest: ISerializedCompanyRequest;
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
  createCompanyRequest: any;
  onCancel: any;
}

export function CompanyRequestCreationComponent(props: ICompanyRequestCreationComponentProps): JSX.Element {
  const { companyRequest, onChange, createCompanyRequest, onCancel } = props;
  if (!companyRequest) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h4">Company Request Registration</Typography>
      <Card className="card-block">
        <DescriptionRow description="Company Request Information" />
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <TextField
              id="company_name"
              type="text"
              value={companyRequest.company_name}
              label="Company name"
              onChange={(event: any) => onChange(event.target.value, 'company_name')}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              id="partner_unique_id"
              type="text"
              value={companyRequest.partner_unique_id}
              label="Partner Id/CIF"
              onChange={(event: any) => onChange(event.target.value, 'partner_unique_id')}
            />
          </Grid>
        </Grid>
        <CompanyRequestAddressInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestUserInfo companyRequest={companyRequest} onChange={onChange} />
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <ButtonContainer alignButtons={'right'}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={createCompanyRequest}>Create Request</Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
