import { useParams } from 'react-router';
import { Card, Tabs, TabPanel, Grid, Typography } from '@emburse/embark';

import { useApi, useTabs } from '../../hooks';
import { Loader } from '../Common/UI';
import { getUserProfileForCompany } from '../../api/routes/user_profiles';
import { ISerializedUserProfile } from '../../types/api/serialized_user_profile';
import { InfoBoxDataItem } from '../Common/InfoBoxDataItem';
import { displayYesNo } from '../../helpers/text_formatters';
import { UserProfileInboxExpenseList } from '../Expenses';
import { TripList } from './TripList';
import { NotificationList } from './NotificationList';

const userProfileTabs = [
  { label: 'Trips' },
  { label: 'Inbox Expenses' },
  { label: 'Notifications' }
];

function UserProfileInfo() {
  const { companyId, userProfileId } = useParams<{ companyId: string, userProfileId: string }>();
  const { item, loading } = useApi<ISerializedUserProfile>(getUserProfileForCompany(companyId, userProfileId));
  const { tabValue, onTabChange } = useTabs(userProfileTabs);

  if (loading) {
    return <Loader />;
  }

  if (!item) {
    return <div>no item</div>;
  }

  const {
    company,
    user,
    state,
    role,
    manages,
    home_currency,
    process_reimbursements_through_abacus,
    travel_enabled
  } = item;

  return (
    <>
      <Typography variant="h4">
        {user.name}
      </Typography>
      <Card className="card-block">
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item>
            <Typography variant="h6">
              User Profile Information
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Company" value={company.name}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="State" value={state}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Role" value={role}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Manages" value={displayYesNo(manages)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Home currency" value={home_currency}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Processes reimbursements" value={displayYesNo(process_reimbursements_through_abacus)}/>
          </Grid>
          <Grid item xs={6} md={3}>
            <InfoBoxDataItem title="Travel status" value={displayYesNo(travel_enabled)}/>
          </Grid>
        </Grid>
      </Card>
      <Tabs
        id="users-tab-bar"
        tabs={userProfileTabs}
        variant="scrollable"
        initialIndex={tabValue}
        onChange={onTabChange}
      />
      <TabPanel value={tabValue} index={0}>
        <TripList companyId={companyId} userProfileId={userProfileId} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <UserProfileInboxExpenseList currency={home_currency}/>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <NotificationList companyId={companyId} userProfileId={userProfileId} />
      </TabPanel>
    </>
  );
}

export { UserProfileInfo };
