import { companyRequestBasePath } from '../../api/routes/company_requests';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { useState } from 'react';
import { GetAuthorizedApiService, IRequestOptionsWithBody } from '../../api';
import { useError } from '../Common/ErrorHandler';
import { CompanyRequestCreationComponent } from './CompanyRequestCreationComponent';
import { useHistory } from 'react-router-dom';
import { DEFAULT_COUNTRY_CODE } from '../../constants';

export function CompanyRequestCreationContainer() {
  const { showError } = useError();

  const { makePostRequest } = GetAuthorizedApiService();
  const [companyRequest, onCompanyRequestChange] = useState<ISerializedCompanyRequest>({
    address_country_code: DEFAULT_COUNTRY_CODE
  } as ISerializedCompanyRequest);
  const navigate = useHistory();

  // event triggers
  function onChange(value: any, field: ISerializedCompanyRequestKeys) {
    const updatedCompanyRequest = {
      ...companyRequest,
      [field]: value
    };

    onCompanyRequestChange(updatedCompanyRequest);
  }

  async function createCompanyRequest() {
    try {
      const postOptions: IRequestOptionsWithBody = {
        body: JSON.stringify(companyRequest)
      };
      await makePostRequest(companyRequestBasePath, postOptions);

      goToCompanyRequestLists();
    } catch (error: any) {
      showError(error);
    }
  }

  function goToCompanyRequestLists() {
    navigate.push('/');
  }

  return (
    <CompanyRequestCreationComponent
      companyRequest={companyRequest}
      onChange={onChange}
      createCompanyRequest={createCompanyRequest}
      onCancel={goToCompanyRequestLists}
    />
  );
}
