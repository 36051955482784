import moment from 'moment';
import { DATE_FORMAT } from '../constants';

export const displayDateTime = (date: number | null) => {
  return date ? moment(date).format(DATE_FORMAT) : '';
};
export const displayYesNo = (value: boolean) => value ? 'YES' : 'NO';

export const maskCardNumber = (cardNumber: number | string | undefined, mask: string = '**** ', reps: number = 3): string => {
  if (!cardNumber) {
    return '';
  }

  return mask.repeat(reps) + cardNumber.toString().slice(-4);
};
