import { useAuth0 } from '@auth0/auth0-react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Container } from '@emburse/embark-core';
import { EmburseThemeProvider } from '@emburse/embark';

import { BreadcrumbsProvider } from '../Navigation/BreadcrumbsProvider';
import { ErrorHandlerProvider } from '../Common/ErrorHandler';
import { Navigation } from '../Navigation';

import { designToken } from '../../designToken';
import './App.css';
import {
  routesForUsersInCompany,
  routesForExpensesInCompany,
  routesForCardProgramsInCompany,
  routesForCreditsInCompany,
  routesForDebitsInCompany,
  routesForCompany,
  routesForCompanyRequests
} from '../../api/route_paths';

export const routes = [
  ...routesForCompany,
  ...routesForUsersInCompany,
  ...routesForExpensesInCompany,
  ...routesForCardProgramsInCompany,
  ...routesForCreditsInCompany,
  ...routesForDebitsInCompany,
  ...routesForCompanyRequests
];

const App = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, error, logout } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return (
      <div>
        <div>Loading ...</div>
        <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
      </div>
    );
  }

  if (!isAuthenticated) {
    const urlParams = new URLSearchParams(window.location.search);
    const loginOptions = {
      invitation: urlParams.get('invitation') || undefined,
      organization: urlParams.get('organization') || undefined,
      organization_name: urlParams.get('organization_name') || undefined
    };

    loginWithRedirect(loginOptions);
    return <></>;
  }

  return (
    <EmburseThemeProvider designToken={designToken}>
      <Router>
        <Navigation />
        <BreadcrumbsProvider>
          <Container classes={{ root: 'app-wrapper' }}>
            <ErrorHandlerProvider>
              <Switch>
                {routes.map(({ path, Component, exact }) => (
                  <Route key={path} path={path} exact={exact}>
                    <Component />
                  </Route>
                ))}
              </Switch>
            </ErrorHandlerProvider>
          </Container>
        </BreadcrumbsProvider>
      </Router>
    </EmburseThemeProvider>
  );
};

export { App };
