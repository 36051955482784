export const DEFAULT_COUNTRY_CODE = 'US';

export const DATE_FORMAT = 'MMM D, YYYY @ h:mma';

export const PER_PAGE_OPTIONS = [5, 10, 25, 50];

const RECORDS_PER_PAGE = {
  ITEMS: 10,
  COMPANIES: 10,
  USERS: 5,
  USER_PROFILES: 5,
  USER_EXPENSES: 5,
  CREDITS: 25,
  DEBITS: 25,
  PROGRAMS: 25,
  SYNC_ERRORS: 25,
  EXPENSE_TAGS: 25,
  EXPENSES: 10,
  CREDIT_EXPENSES: 5,
  SUB_ACCOUNTS: 5,
  CARDS: 10,
  DEBIT_EXPENSES: 25,
  EXPENSE_RULES: 5,
  RECEIPTS: 10,
  USER_EXPENSE_TAGS: 5,
  USER_PROFILE_EXPENSES: 10,
  NOTIFICATIONS: 25,
  FEATURES: 10,
  COMPANY_REQUESTS: 25
};

export const ITEMS_PER_PAGE = RECORDS_PER_PAGE.ITEMS;
export const COMPANIES_PER_PAGE = RECORDS_PER_PAGE.COMPANIES;
export const USERS_PER_PAGE = RECORDS_PER_PAGE.USERS;
export const USER_PROFILES_PER_PAGE = RECORDS_PER_PAGE.USER_PROFILES;
export const USER_EXPENSES_PER_PAGE = RECORDS_PER_PAGE.USER_EXPENSES;
export const CREDITS_PER_PAGE = RECORDS_PER_PAGE.CREDITS;
export const DEBITS_PER_PAGE = RECORDS_PER_PAGE.DEBITS;
export const PROGRAMS_PER_PAGE = RECORDS_PER_PAGE.PROGRAMS;
export const SYNC_ERRORS_PER_PAGE = RECORDS_PER_PAGE.SYNC_ERRORS;
export const EXPENSE_TAGS_PER_PAGE = RECORDS_PER_PAGE.EXPENSE_TAGS;
export const EXPENSES_PER_PAGE = RECORDS_PER_PAGE.EXPENSES;
export const CREDIT_EXPENSES_PER_PAGE = RECORDS_PER_PAGE.CREDIT_EXPENSES;
export const SUB_ACCOUNTS_PER_PAGE = RECORDS_PER_PAGE.SUB_ACCOUNTS;
export const CARDS_PER_PAGE = RECORDS_PER_PAGE.CARDS;
export const DEBIT_EXPENSES_PER_PAGE = RECORDS_PER_PAGE.DEBIT_EXPENSES;
export const EXPENSE_RULES_PER_PAGE = RECORDS_PER_PAGE.EXPENSE_RULES;
export const RECEIPTS_PER_PAGE = RECORDS_PER_PAGE.RECEIPTS;
export const USER_EXPENSE_TAGS_PER_PAGE = RECORDS_PER_PAGE.USER_EXPENSE_TAGS;
export const USER_PROFILE_EXPENSES_PER_PAGE = RECORDS_PER_PAGE.USER_PROFILE_EXPENSES;
export const NOTIFICATIONS_PER_PAGE = RECORDS_PER_PAGE.NOTIFICATIONS;
export const FEATURES_PER_PAGE = RECORDS_PER_PAGE.FEATURES;
export const COMPANY_REQUESTS_PER_PAGE = RECORDS_PER_PAGE.COMPANY_REQUESTS;
