import { Button, ButtonContainer, Card, Grid, TextField, Typography } from '@emburse/embark';

import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';
import { CompanyRequestUserInfo, CompanyRequestAddressInfo, DescriptionRow, CompanyRequestDetails } from './subComponents';

interface ICompanyRequestInfoComponentProps {
  onChange: (value: any, field: ISerializedCompanyRequestKeys) => void;
  onUpdate: () => Promise<void>;
  onResendInvitation: () => Promise<void>;
  companyRequest: ISerializedCompanyRequest;
}

export function CompanyRequestInfoComponent(props: ICompanyRequestInfoComponentProps): JSX.Element {
  const { onChange, onUpdate, onResendInvitation, companyRequest } = props;

  if (!companyRequest) {
    return <></>;
  }

  return (
    <>
      <Typography variant="h4">{companyRequest.company_name}</Typography>
      <Card className="card-block">
        <CompanyRequestDetails companyRequest={companyRequest} />
        <DescriptionRow description="Company Request" />
        <Grid container direction="row" spacing={5} wrap="wrap">
          <Grid item xs={6} md={3}>
            <TextField
              id="company_name"
              type="text"
              value={companyRequest.company_name}
              label="Company Name"
              onChange={(event: any) => onChange(event.target.value, 'company_name')}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <TextField
              id="partner_unique_id"
              type="text"
              value={companyRequest.partner_unique_id}
              label="Partner Id/CIF"
              onChange={(event: any) => onChange(event.target.value, 'partner_unique_id')}
            />
          </Grid>
        </Grid>
        <CompanyRequestAddressInfo companyRequest={companyRequest} onChange={onChange} />
        <CompanyRequestUserInfo companyRequest={companyRequest} onChange={onChange} />
        <Grid container spacing={5} wrap="wrap">
          <Grid item xs={12}>
            <ButtonContainer alignButtons={'right'}>
              <Button onClick={onResendInvitation}>Resend Invite</Button>
              <Button onClick={onUpdate}>Save Changes</Button>
            </ButtonContainer>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
