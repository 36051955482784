import { TabPanel, Tabs } from '@emburse/embark';

import { CompanyRequestList } from '../CompanyRequests';
import { CompanyList } from '.';
import { useTabs } from '../../hooks';
import { UserSearch } from '../Users/UserSearch'

const companyTabs = [{ label: 'Companies' }, { label: 'Invited Companies' }, { label: 'User Search' }];

function CompanyViews() {
  const { tabValue, onTabChange } = useTabs(companyTabs);

  return (
    <>
      <Tabs id="company-list-tabs" variant="scrollable" tabs={companyTabs} onChange={onTabChange} />
      <TabPanel value={tabValue} index={0}>
        <CompanyList />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <CompanyRequestList />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <UserSearch />
      </TabPanel>
    </>
  );
}

export { CompanyViews };
