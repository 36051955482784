import { useParams } from 'react-router';
import { useEffect, useState } from 'react';

import { CompanyRequestInfoComponent } from './CompanyRequestInfoComponent';
import { CompanyRequestNotFound } from './subComponents';
import { Loader } from '../Common/UI';
import { useError } from '../Common/ErrorHandler';
import { useApi } from '../../hooks';
import { IRequestOptionsWithBody, GetAuthorizedApiService } from '../../api';
import { getCompanyRequestByIdPath } from '../../api/routes/company_requests';
import { ISerializedCompanyRequest, ISerializedCompanyRequestKeys } from '../../types/api/serialized_company_request';

export function CompanyRequestInfoContainer() {
  const { companyRequestId } = useParams<{ companyRequestId: string }>();
  const companyRequestByIdPath = getCompanyRequestByIdPath(companyRequestId);
  const { showError } = useError();

  const { item: requestFromDB, loading: loadingRequests } = useApi<ISerializedCompanyRequest>(companyRequestByIdPath);
  const { makePutRequest, makePostRequest } = GetAuthorizedApiService();

  const [requestState, updateRequestState] = useState(requestFromDB);

  // when we get the request from the DB/request from the DB changes update the requestState we use to fill the form fields,
  useEffect(() => updateRequestState(requestFromDB), [requestFromDB]);

  if (loadingRequests) {
    return <Loader />;
  }

  if (!requestFromDB || !requestState) {
    return CompanyRequestNotFound();
  }

  // event triggers
  function onChange(value: any, field: ISerializedCompanyRequestKeys) {
    if (!requestState) {
      return;
    }
    const updatedCompanyRequest = {
      ...requestState,
      [field]: value
    };

    updateRequestState(updatedCompanyRequest);
  }

  async function updateCompanyRequest() {
    try {
      const putOptions: IRequestOptionsWithBody = {
        body: JSON.stringify(requestState)
      };
      await makePutRequest(companyRequestByIdPath, putOptions);
    } catch (error: any) {
      showError(error);
    }
  }

  async function resendInvitation() {
    await makePostRequest(`${companyRequestByIdPath}/invite`);
  }

  return (
    <CompanyRequestInfoComponent
      onChange={onChange}
      onUpdate={updateCompanyRequest}
      onResendInvitation={resendInvitation}
      companyRequest={requestState}
    />
  );
}
